import { NoMatch } from "./pages/NoMatch";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { startTimer } from "./actions/clock";
import { setUser } from "./actions/user";
import { useEffect, useState } from "react";
import Parse from "parse/dist/parse.min.js";
import { isConnected } from "./utility/UserUtils";
import { b4config } from "./datas/b4appconfig";
import { setThemeList } from "./actions/theme";
import { setDocumentDimension } from "./actions/dimension";
import Home from "./pages/Home/Home";
import { setArticlesList } from "./actions/article";
import { configs } from "./configs/config";
import { setVisitorsList } from "./actions/visitors";
import { useDispatch } from "react-redux";
import {
  ParseClasse,
  CreateClasse,
  subscriptionInLiveQuery,
  subscribeForWith,
} from "./utility/ParseUtils";
import axios from "axios";
import { WrapperSubjects } from "./pages/Home/WrapperSubjects";
import { WrapperArticleInSubject } from "./pages/Home/WrapperArticleInSubject";
import { setMatouStatus } from "./actions/serveur";
import "./App.css";
import { WrapperEmail } from "./pages/Home/WrapperEmail";
/**
 *
 * @param props
 * @returns
 */
const ConnectedApp = (props: any) => {
  const { user, theme } = props;
  const [client, setClient] = useState();
  const [userIP, setUserIP] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialisation connection Parse
    Parse.serverURL = b4config.server;
    // Initialisation
    Parse.initialize(b4config.appId, b4config.jsKey);

    ParseClasse("Articles", (rep: any) => {
      props.setArticlesList(JSON.parse(JSON.stringify(rep)));
    });
  }, []);

  useEffect(() => {
    let client = subscriptionInLiveQuery();
    setClient(client);
  }, []);

  useEffect(() => {
    if (!client) return;
    console.log("Abonnements livequery");
    subscribeForWith("Visitors", client, () => loadReloadVisitorsList());
  }, [client]);

  const loadReloadVisitorsList = () => {
    ParseClasse("Visitors", (rep: any) => {
      props.setVisitorsList(JSON.parse(JSON.stringify(rep)));
    });
  };
  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        console.log("Adresse IP=" + response.data.ip);
        CreateClasse(
          "Visitors",
          {
            ip: response.data.ip,
            date: new Date(),
          },
          () => {
            console.log("Enregistrement IP=" + response.data.ip);
          },
          (e) => console.error("Error ", e)
        );
      })

      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
    loadReloadVisitorsList();
  }, []);

  useEffect(() => {
    if (user) console.log("Connected ? " + isConnected(user));
  }, [user]);

  useEffect(() => {
    ParseClasse("Theme", (theme: any) => {
      console.log("Theme list");
      props.setThemeList(JSON.parse(JSON.stringify(theme)));
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      props.setDocumentDimension({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    };
    const handleTouchMove = (e) => {
      e.preventDefault();
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("touchmove", handleTouchMove);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log("Checking status");
      checkMatouStatus();
    }, configs.intervalTime);
    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, []); // Déclencher uniquement lors du montage initial

  const checkMatouStatus = () => {
    console.log("Checking status on " + configs.matou_health);
    fetch(configs.matou_health, {
      method: "GET", // ou 'POST' selon le cas
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error("Response was not JSON: " + text);
        });
      })
      .then((data) => {
        console.log("DATA " + data.status);
        if (data.status === "up") {
          props.setMatouStatus("up");
        } else {
          props.setMatouStatus("down");
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        props.setMatouStatus("down");
      });
  };

  /**
   *
   */
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Articles/:subject" element={<WrapperSubjects />} />
      <Route
        path="/Articles/:subject/:article"
        element={<WrapperArticleInSubject />}
      />
      <Route path="/Contact" element={<WrapperEmail />} />
      <Route path="/*" element={<NoMatch />} />
    </Routes>
  );
};
/**
 *
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    startTimer: () => dispatch(startTimer()),
    setUser: (user: any) => dispatch(setUser(user)),
    setThemeList: (themeList: any) => dispatch(setThemeList(themeList)),
    setDocumentDimension: (dimension: any) =>
      dispatch(setDocumentDimension(dimension)),
    setArticlesList: (list: any) => dispatch(setArticlesList(list)),
    setVisitorsList: (list: any) => dispatch(setVisitorsList(list)),
    setMatouStatus: (status: any) => dispatch(setMatouStatus(status)),
  };
};

/**
 *
 * @param state
 * @returns
 */
const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    theme: state.theme.theme,
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

export default App;
