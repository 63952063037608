import React from "react";
import { useParams } from "react-router-dom";
import Home from "./Home";
/**
 * 
 * @returns 
 */
export const WrapperArticleInSubject=()=>{
    console.log("Params=",useParams())
    let {article,subject}=useParams();
    return <Home article={article}/>
}