import React from "react";
import { Article } from "../../components/Article/Article";
import { VignetteArticle } from "../../components/VignetteArticle/VignetteArticle";
import './articles.css'
/**
 * 
 * @param props 
 */
export const Articles = ({
    articles,
    subject,
}: any) => {
    const getVignetteArticlesView = () => {
        console.log('getVignetteArticles=',getArticlesRelativeToSubject(articles, subject)[0]);
        return (
            <div className="liste_vignettes">
                {
                    getArticlesRelativeToSubject(articles, subject).map((object: any) => (
                        <VignetteArticle
                            key={object.objectId}
                            object={object}
                        />
                    ))}
            </div>
        );
        return
    }

    /**
     * 
     * @param articles 
     * @param subject 
     * @returns 
     */
    const getArticlesRelativeToSubject = (articles, subject) => {
        console.log("getArticlesRelativeToSubject ARTICLES="+articles+ " SUBJECT="+subject)
        return articles.filter((object: any) => {
          
            if (subject) return object.Category === subject;
            else return true;
        }).filter(o => {
            console.log("Published=" + o.published)
            return o.published === true
        })
    }

    return (getArticlesRelativeToSubject(articles, subject).length > 1) ? getVignetteArticlesView()
        : <Article
            key={getArticlesRelativeToSubject(articles, subject)[0].objectId}
            object={getArticlesRelativeToSubject(articles, subject)[0]}
        />

}


