const initialState = {  
    matou_status: "checking", // "up", "down", ou "checking"
  };
  
  const serveur = (state = initialState, action) => {
    //console.log("Action="+action.type)
    //console.log("Payload=", action.payload)
    switch (action.type) {
     
      case "SET_MATOU_STATUS":
        console.log("set matou status " + action.payload);
        return {
          ...state,
          matou_status: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default serveur;
  