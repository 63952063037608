import React from "react";
import { useParams } from "react-router-dom";
import Home from "./Home";
/**
 * 
 * @returns 
 */
export const WrapperSubjects=()=>{
    let {subject}=useParams();
    console.log("subject=",subject);
    return <Home subject={subject}/>
}