import { combineReducers } from 'redux';

import user from "./user";
import theme from './theme';
import dimension from './dimension';
import articles from './articles';
import visitors from './visitors';
import serveur from './serveur'
/**
 * 
 */
const rootReducer=combineReducers(
   {
    user,theme,dimension,articles,visitors,serveur
   })
export default rootReducer;
