import React, { useState } from 'react';
import { ArticleIpynb } from './ArticleIpynb';
import { ArticleMd } from './ArticleMd';
import "./article.css"
/**
 * 
 * @param param0 
 * @returns 
 */
export const Article = ({ object }: any) => {
    console.log("Object=",object)
    const { Ipynb,Md,Language } = object;
    // console.log("Ipynb: ", Ipynb);
    // console.log("Md: ", Md);
    const getView=()=>{
        if (!Md) return <ArticleIpynb Ipynb={Ipynb} language={Language}/>
        else return <ArticleMd Md={Md}/>
    }
    return (
        getView() 
    );
};

