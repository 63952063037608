import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Articles } from "../Articles/Articles";
import avatar from "../../media/image.png";
import AtomAnimation from "../../components/AtomAnimation";
import { Article } from "../../components/Article/Article";
import { connect } from "react-redux";
import "./home.css";
import { configs } from "../../configs/config";
import ModalComponent from "../../components/Modal/ModalComponent";
import { any } from "prop-types";
import emailjs from "@emailjs/browser";

/**
 *
 * @param props
 * @returns
 */
export const ConnectedHome = (props: any) => {
  const {
    articles,
    dimension,
    subject,
    article,
    visitors,
    matou_status,
    email,
  } = props;
  const [matouModalIsOpen, setMatouModalIsOpen] = useState(false);
  let navigation = useNavigate();
  const form = useRef();
  console.log("Article=" + article);
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const maxLength = 30;
  /**
   *
   * @returns
   */

  const getPublishedSubjectList = () => {
    return getStringOfPublishedSubject()
      .split(",")
      .map((category) => (
        <div
          key={category}
          className="m-h4"
          onClick={() => navigation("/Articles/" + category)}
        >
          {category}
        </div>
      ));
  };
  console.log("Articles ", articles);
  useEffect(() => {
    if (subject) {
      document.querySelector(".right").style.background =
        "background: linear-gradient(277deg, rgba(2,0,36,1) 0%, rgba(230,207,228,1) 0%, rgba(255,255,255,1) 100%);";
    }
  }, [subject]);

  const getStringOfPublishedSubject = () => {
    let subjects = "";
    articles
      .filter((object) => object.published === true)
      .map((obj) => {
        if (subjects.indexOf(obj.Category) === -1)
          subjects += subjects.length === 0 ? obj.Category : "," + obj.Category;
      });
    console.log("Subjects=", subjects);
    return subjects;
  };

  const countDistinctIPsInVisitors = () => {
    const ipCountMap = new Map();

    visitors.forEach((obj) => {
      const ip = obj["ip"];
      if (ipCountMap.has(ip)) {
        ipCountMap.set(ip, ipCountMap.get(ip) + 1);
      } else {
        ipCountMap.set(ip, 1);
      }
    });

    return ipCountMap.size; // Renvoie la taille de la Map, c'est-à-dire le nombre d'IP distinctes
  };

  //   const distinctIPCount = countDistinctIPsInVisitors();
  //   console.log(`Nombre d'adresses IP distinctes : ${distinctIPCount}`);

  /**
   *
   * @param inputString
   * @param maxLength
   * @returns
   */
  const formatString = (inputString, maxLength) => {
    if (inputString.length <= maxLength) {
      return inputString;
    } else {
      let formattedString = "";
      for (let i = 0; i < inputString.length; i += maxLength) {
        formattedString += inputString.substr(i, maxLength) + "\n";
      }

      return formattedString;
    }
  };

  const getPublishedSubject = () => {
    return (
      <div className="m-h6">
        {formatString(getStringOfPublishedSubject(), 30)}
      </div>
    );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };
  const getRight = () => {
    console.log("Email=" + email);
    if (email === true) {
      return (
        <div className="email-form">
          <h2>Contactez-moi</h2>
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form>
        </div>
      );
    } else return getArticleOrArticles();
  };
  const getArticleOrArticles = () => {
    if (!articles || articles.length === 0) {
      return null; // Ne rien afficher si articles est undefined ou un tableau vide
    }

    console.log(
      "Getting Article=" + article + " in " + articles.length + " articles"
    );

    return article ? (
      <Article
        key={article}
        object={articles.filter((o) => o.objectId === article)[0]}
      />
    ) : (
      getArticles()
    );
  };

  const titleStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Ajuster la hauteur selon vos besoins
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Couleur de fond avec opacité
    color: "white", // Couleur du texte
    textAlign: "center",
  };

  const AtomAnimationMemo = useMemo(
    () => <AtomAnimation articles={articles} />,
    [articles]
  );

  const getArticles = () => {
    if (subject) {
      return <Articles articles={articles} subject={subject}></Articles>;
    } else {
      return (
        <div style={titleStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {AtomAnimationMemo}
          </div>
        </div>
      );
    }
  };

  const handleMatouClic = () => {
    console.log("Leonard Clic");
    if (matou_status === "up") setMatouModalIsOpen(true);
    else {
      alert("Matou non operationnel");
    }
  };
  useEffect(() => emailjs.init("FN6tIqN-iwLx55S-O"), []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_kx9use6", "template_ihzad8q", form.current).then(
      () => {
        console.log("SUCCESS!");
        navigation("/");
      },
      (error) => {
        alert("FAILED..." + error.text);
        console.log("FAILED...", error.text);
      }
    );

    e.target.reset();
  };

  return (
    <>
      <header></header>
      <main>
        <div className="row">
          <div className="left">
            <div className="column">
              <div className="one">
                <img
                  className="avatar"
                  style={{
                    cursor: "pointer",
                    width: "80px",
                    height: "80px",
                    border: `2px solid ${
                      matou_status === "up" ? "green" : "red"
                    }`,
                  }}
                  src={avatar}
                  onClick={handleMatouClic}
                />
                <div className="m-h1">Bruno Boissie</div>
                <div className="m-h2">
                  Nombre de visiteurs: {countDistinctIPsInVisitors()}
                </div>
                <button
                  className="email-button"
                  onClick={() => navigation("/contact")}
                >
                  Email me
                </button>
              </div>
              <div className="two">
                {getPublishedSubject()}
                <div className="column menu">{getPublishedSubjectList()}</div>
              </div>
            </div>
          </div>
          <div className="right">{getRight()}</div>
        </div>
        <ModalComponent
          isOpen={matouModalIsOpen}
          url={configs.matou_url}
          onRequestClose={() => setMatouModalIsOpen(false)}
        />
      </main>
      <footer></footer>
    </>
  );
};

/**
 *
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch: any) => {
  return {};
};
/**
 *
 * @param state
 * @returns
 */
const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    theme: state.theme.theme,
    articles: state.articles.articles,
    visitors: state.visitors.visitors,
    matou_status: state.serveur.matou_status,
  };
};

const Home = connect(mapStateToProps, mapDispatchToProps)(ConnectedHome);

export default Home;
