import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IpynbRenderer } from "react-ipynb-renderer-katex";

// Formula renderer for katex
import 'katex/dist/katex.min.css';
// Jupyter theme
//import "react-ipynb-renderer-katex/dist/styles/monokai.css";
import "./articleipynb.css"
import "./article.css"


/**
 * 
 */
export const ArticleIpynb = ({ Ipynb,language }: any) => {
    const [json, setJson] = useState();
    const [isLoading, setIsLoading] = useState(true);
    console.log("language ",language);
    //console.log("Affichage IPYNB", JSON.stringify(json));
    const { url } = Ipynb;

    useEffect(() => {
        fetch(url)
            .then(response => response.text())
            .then(text => {
                let jsonData = JSON.parse(text);
                setJson(jsonData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Une erreur est survenue lors de la récupération du fichier :', error);
                setIsLoading(false);
            });
    }, []);

    const getView = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <CircularProgress />
                </div>
            )
        } else if (!json) {
            return <></>; // Aucun contenu à afficher
        } else {
            return (
                <IpynbRenderer
                    language={language}
                    ipynb={json}
                    syntaxTheme={"solarizedlight"}
                    seqAsExecutionCount={true}
                />
            );
        }
    }

    return (
        getView()
    );
};
