// ModalComponent.jsx
import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaPaperPlane, FaTimes } from 'react-icons/fa';
import './modalcomponent.css';
import "katex/dist/katex.min.css";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import Markdown from "react-markdown";

const ModalComponent = ({ isOpen, onRequestClose, url }) => {
  const [input, setInput] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => setInput(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(''); // Réinitialiser le message avant de commencer une nouvelle requête
    setIsLoading(true); // Définir l'état de chargement sur vrai
    const stream = new EventSource(`${url}?query=${encodeURIComponent(input)}`);
    let answer = "";
    stream.onmessage = (event) => {
      try {
        const jsonData = JSON.parse(event.data);
        if (jsonData.message) {
          answer += jsonData.message;
          setMessage(answer);
        }
      } catch (e) {
        console.error("Error parsing JSON:", e);
      }
    };

    stream.onerror = () => {
      stream.close();
      setIsLoading(false); // Définir l'état de chargement sur faux après la fin de la réponse
      setMessage(answer);
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="chatbot-modal"
      overlayClassName="chatbot-modal-overlay"
    >
      <div className="chatbot-header">
        <h2>ChatBot</h2>
        <FaTimes onClick={onRequestClose} className="close-icon" />
      </div>
      <div className="chatbot-messages">
        {message && (
          <div className="chatbot-message">
            <Markdown
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >
              {message}
            </Markdown>
          </div>
        )}
        {isLoading && (
          <div className="chatbot-loading">
            Waiting for response...
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} className="chatbot-form">
        <input
          type="text"
          value={input}
          onChange={handleChange}
          placeholder="Enter your question"
          className="chatbot-input"
          disabled={isLoading} // Désactiver l'entrée pendant le chargement
        />
        <button type="submit" className="chatbot-submit" disabled={isLoading}>
          <FaPaperPlane />
        </button>
      </form>
    </Modal>
  );
};

export default ModalComponent;
