import { SET_VISITORS_LIST } from "../constants/action-type";
import Logger from "../logging/Logger";

let logger = Logger.getInstance();

//

const initialState = {
    visitors: [],
};
/**
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const visitors = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISITORS_LIST:
            console.log("SET_VISITORS_LIST", action.list);
            if (action.list) {
                return {
                    ...state,
                    visitors: action.list,
                };
            } else return state;
        default:
            return state;
    }
};

export default visitors;
