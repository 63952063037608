import React from "react";
import { useNavigate} from "react-router-dom";
import './vignettearticle.css'
/**
 * 
 * @param param0 
 * @returns 
 */
export const VignetteArticle = ({ object }: any) => {
    let navigation = useNavigate();
    return (
        <div className="vignette" onClick={()=>navigation("/Articles/"+object.Category+"/"+object.objectId)}>
            <div className="title">{new Date(object.createdAt).toLocaleString()}</div>
            <h1>{object.name}</h1>
        </div>
    )
}

