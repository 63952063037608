import React, { useEffect, useState } from 'react';
import Sketch from 'react-p5';

const AtomAnimation = ({ articles = [] }) => {
  const [isCanvasCreated, setIsCanvasCreated] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("");
  const [textPosition, setTextPosition] = useState({ x: 50, y: 50 });
  const [currentColor, setCurrentColor] = useState({ r: 255, g: 100, b: 100 }); // Initial color
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const setup = (p5, canvasParentRef) => {
    if (!isCanvasCreated) {
      p5.createCanvas(dimensions.width, dimensions.height).parent(canvasParentRef);
      p5.background(10);
      setIsCanvasCreated(true);
    }
  };

  const draw = (p5) => {
    p5.background(10, 10); // Les trois premiers sont les composantes RGB, le dernier est l'alpha

    p5.noStroke();
    
    //console.log("CurrentColor=",currentColor)
    p5.fill(currentColor.r, currentColor.g, currentColor.b, 100); // Use currentColor for the ellipses

    for (let i = 0; i < 10; i++) {
      let x = p5.noise(p5.frameCount * 0.01 + i) * p5.width;
      let y = p5.noise(p5.frameCount * 0.01 + i + 1000) * p5.height;
      p5.ellipse(x, y, 20, 20);
    }

    // Display the current article title with currentColor
    p5.fill(currentColor.r, currentColor.g, currentColor.b);
    p5.textSize(32); // Augmente la taille du texte
    p5.text(currentTitle, textPosition.x, textPosition.y);
  };

  const windowResized = (p5) => {
    if (isCanvasCreated) {
      const newWidth = p5.windowWidth;
      const newHeight = p5.windowHeight;
      p5.resizeCanvas(newWidth, newHeight);
      setDimensions({ width: newWidth, height: newHeight });
    }
  };

  useEffect(() => {
    console.log('AtomAnimation is mounted');
    const intervalId = setInterval(() => {
      if (articles.length > 0) {
        const randomIndex = Math.floor(Math.random() * articles.length);
        const randomX = Math.random() * dimensions.width;
        const randomY = Math.random() * dimensions.height;
        setCurrentTitle(articles[randomIndex].Title || "No title");
        setTextPosition({ x: randomX, y: randomY });

        // Change color randomly
        setCurrentColor({
          r: Math.floor(Math.random() * 256),
          g: Math.floor(Math.random() * 256),
          b: Math.floor(Math.random() * 256)
        });
      }
    }, 250);

    return () => {
      console.log('AtomAnimation is unmounted');
      clearInterval(intervalId);
      setIsCanvasCreated(false);
    };
  }, [articles, dimensions]);

  return <Sketch setup={setup} draw={draw} windowResized={windowResized} />;
};

export default AtomAnimation;
