import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./article.css"
/**
 * 
 * @param param0 
 * @returns 
 */
export const ArticleMd = ({ Md }) => {
    const [mdText, setMdText] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { url } = Md;
    console.log("Markdown=",Md)
    useEffect(() => {
        fetch(url)
            .then(response => response.text())
            .then(text => {
                console.log("Text markdown=",text)
                setMdText(text);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Une erreur est survenue lors de la récupération du fichier :', error);
                setIsLoading(false);
            });
    }, []);
    const markdown = 'This ~is not~ strikethrough, but ~~this is~~!'
    const getView = () => {
        if (isLoading) {
            return (
                <div className="spinner-container">
                    <CircularProgress />
                </div>
            )
        } else if (!mdText) {
            return <></>;
        } else {
            return (
                <Markdown rehypePlugins={[rehypeRaw]} children={mdText} />
            );
        }
    }

    return (
        getView()
    );
};
